import "../styles/index.scss";
import Swiper, { Navigation, Pagination, Autoplay, EffectFade } from "swiper";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import { Dropdown } from "tailwindcss-stimulus-components";
import Turbo from "@hotwired/turbo"

// import Swiper styles
import "swiper/swiper-bundle.css";

Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);

console.info("Bridgetown is loaded!");
const application = Application.start();
const context = require.context("./controllers", true, /.js$/);
application.load(definitionsFromContext(context));
application.register("dropdown", Dropdown);
