import Swiper from "swiper";
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["container"];

  connect() {
    this.swiper = new Swiper(this.containerTarget, {
      loop: true,
      // effect: "fade",
      autoplay: {
        delay: 15000
      },
      autoHeight: false
    });
  }
}
